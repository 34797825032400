import { checkApplyPeriod } from '../utils/checkApplyPeriod'
import { IApplyStatusList } from '../types'

interface IKDTCardPeriodBadgeProps {
  startedAt: string
  endedAt: string
}

const KDTCardPeriodBadge = ({ startedAt, endedAt }: IKDTCardPeriodBadgeProps) => {
  const { status, dDay } = checkApplyPeriod(startedAt, endedAt)
  const { label, style } = APPLY_STATUS_LIST[status]

  return (
    <div className="flex flex-row items-center justify-start gap-1 lg:gap-1.5">
      <span
        className={`${style} rounded px-1.5 py-1 text-xs font-semibold md:px-2 md:text-[13px] lg:rounded-md lg:px-2.5 lg:text-sm`}
      >
        {status === 'applyDeadline' ? (dDay === 0 ? '오늘마감' : `${label} D-${dDay}`) : label}
      </span>
    </div>
  )
}

export default KDTCardPeriodBadge

const APPLY_STATUS_LIST: IApplyStatusList = {
  early: {
    label: '사전알림',
    style: 'bg-[#FFEFE3] text-yellow-450',
  },
  apply: {
    label: '모집중',
    style: 'bg-primary text-white',
  },
  applyDeadline: {
    label: '마감임박',
    style: 'bg-primary text-white',
  },
  applyEnd: {
    label: '모집종료',
    style: 'bg-[#EBEBEB] text-gray-500',
  },
}
